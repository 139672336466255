const navbar = document.querySelector(".navbar")
      navbarInner = document.querySelector(".navbar__inner")
      navbarItems = document.querySelectorAll(".navbar-menu__link")
      navbarBackdrop = document.querySelector(".navbar__backdrop")
      navbarBurger = document.querySelector(".navbar__burger")
      navbarNumber = document.querySelector(".navbar__number")

try {
  const toggleNavbar = () => {
    navbarInner.classList.toggle(`${navbarInner.classList[0]}--opened`)
  }

  const closeNavbar = () => {
    navbarInner.classList.remove(`${navbarInner.classList[0]}--opened`)
  }

  navbarBurger.addEventListener("click", () => toggleNavbar() )
  navbarBackdrop.addEventListener("click", () => closeNavbar() )
  navbarNumber.addEventListener("click", () => closeNavbar() )
  navbarItems.forEach(item => {
    item.addEventListener("click", () => closeNavbar() )
  })

  let new_scroll = 111
  let last_scroll

  window.addEventListener("scroll", () => {
    last_scroll = window.scrollY
    if (new_scroll < last_scroll && last_scroll > 650) {
      navbar.classList.remove("slideDown")
      navbar.classList.add("slideUp")
      closeNavbar()
    } else if (new_scroll > last_scroll) {
      navbar.classList.remove("slideUp")
      navbar.classList.add("slideDown")
      closeNavbar()
    }

    new_scroll = last_scroll
  })
} catch(error) {
  console.error("Навбар не найден")
  console.error(error)
}
